import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import detailRoute from "./detail";
import generalRoute from "./general";
import userRoute from "./user";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  generalRoute,
  detailRoute,
  userRoute,
  {
    path: "/product-prices",
    name: "ProductPricesIframe",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "@/components/UIComponents/PriceMonitor.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 };
  },
});
router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title =
    toRoute.meta && toRoute.meta.title
      ? toRoute.meta.title
      : "दाङ कृषि उपज बजार";

  next();
});

export default router;
